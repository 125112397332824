<template>
  <div class="container1">
    <div class="top-box">
      <div class="top1">
        <p style="font-size: 18px; color: #434343; margin-left: 30px">总收入</p>
        <p class="top-p">
          <img
            style="height: 30px; width: 30px; margin-right: 6px"
            src="../../assets/imgs/q2.png"
            alt=""
          /><samp>¥ {{IncomeTotalPrice || 0}}</samp>
        </p>
        <ul class="top-ul1">
          <li>
            <p><span class="span1"></span><span>课程订单</span></p>
            <samp class="span2">¥ {{IncomeTotal.study_card}}</samp>
          </li>
          <li>
            <p><span class="span1"></span><span>权益点充值</span></p>
            <samp class="span2">¥ {{IncomeTotal.point}}</samp>
          </li>
          <li>
            <p><span class="span1"></span><span>消费券购买</span></p>
            <samp class="span2">¥ {{IncomeTotal.coupon}}</samp>
          </li>
          <li>
            <p><span class="span1"></span><span>会员卡购买</span></p>
            <samp class="span2">¥ {{IncomeTotal.member}}</samp>
          </li>
        </ul>
      </div>
      <div class="top1" style="border-left: 8px solid #8565ff">
        <p style="font-size: 18px; color: #434343; margin-left: 30px">总支出</p>
        <p class="top-p">
          <img
            style="height: 30px; width: 30px; margin-right: 6px"
            src="../../assets/imgs/q1.png"
            alt=""
          /><samp>¥ {{ExpendTotalPrice || 0}}</samp>
        </p>
        <ul class="top-ul1">
          <li>
            <p>
              <span class="span1" style="background-color: #8565ff"></span
              ><span>退款</span>
            </p>
            <samp class="span2">¥ {{ExpendTotal.refund || 0}}</samp>
          </li>
          <li>
            <p>
              <span class="span1" style="background-color: #8565ff"></span
              ><span>商户券核销</span>
            </p>
            <samp class="span2">¥ {{ExpendTotal.coupon || 0}}</samp>
          </li>
          <li>
            <p>
              <span class="span1" style="background-color: #8565ff"></span
              ><span>课程结算</span>
            </p>
            <samp class="span2">¥ {{ExpendTotal.study_card || 0}}</samp>
          </li>
          <li>
            <p>
              <span class="span1" style="background-color: #8565ff"></span
              ><span>提现</span>
            </p>
            <samp class="span2">¥ {{ExpendTotal.transfer || 0}}</samp>
          </li>
        </ul>
      </div>
      <div class="top-right">
        <div class="box-nr">
          <div class="imgbox">
            <img class="imgc" src="../../assets/imgs/uasntp/1s.png" />
          </div>
          <ul>
            <li class="top-li">用户总数</li>
            <li>{{userCount.total}}</li>
          </ul>
        </div>
        <div class="box-nr">
          <div class="imgbox">
            <img class="imgc" src="../../assets/imgs/uasntp/2s.png" />
          </div>
          <ul>
            <li class="top-li">小店</li>
            <li>{{totalInfo.merchantCount || 0}}</li>
          </ul>
        </div>
        <div class="box-nr">
          <div class="imgbox">
            <img class="imgc" src="../../assets/imgs/uasntp/3s.png" />
          </div>
          <ul>
            <li class="top-li">机构</li>
            <li>{{totalInfo.mechanismCount || 0}}</li>
          </ul>
        </div>
        <div class="box-nr">
          <div class="imgbox">
            <img class="imgc" src="../../assets/imgs/uasntp/4s.png" />
          </div>
          <ul>
            <li class="top-li">团长</li>
            <li>{{totalInfo.RecommenderCount || 0}}</li>
          </ul>
        </div>
        <div class="box-nr">
          <div class="imgbox">
            <img class="imgc" src="../../assets/imgs/uasntp/5s.png" />
          </div>
          <ul>
            <li class="top-li">课程</li>
            <li>{{totalInfo.masterAppointmentCount || 0}}</li>
          </ul>
        </div>
        <div class="box-nr">
          <div class="imgbox">
            <img class="imgc" src="../../assets/imgs/uasntp/6s.png" />
          </div>
          <ul>
            <li class="top-li">消费券</li>
            <li>{{totalInfo.userCouponCount || 0}}</li>
          </ul>
        </div>
        <div class="box-nr">
          <div class="imgbox">
            <img class="imgc" src="../../assets/imgs/uasntp/7s.png" />
          </div>
          <ul>
            <li class="top-li">会员卡</li>
            <li>{{totalInfo.userMemberCount || 0}}</li>
          </ul>
        </div>
        <div class="box-nr">
          <div class="imgbox">
            <img class="imgc" src="../../assets/imgs/uasntp/coach.png" />
          </div>
          <ul>
            <li class="top-li">教练</li>
            <li>{{totalInfo.coachCount || 0}}</li>
          </ul>
        </div>
      </div>
    </div>
    <div class="nr-z">
      <div class="nr-s">
        <el-date-picker
      v-model="date"
      type="daterange"
      align="right"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      value-format="yyyy-MM-dd"
      :picker-options="pickerOptions"
      @change="changeTime">
    </el-date-picker>
        <p style="width: 50px"></p>
        <el-tabs v-model="activeName" @tab-click="tabChange">
          <el-tab-pane
            v-for="(item, index) in tabslist"
            :key="index"
            :label="item"
            :value="index"
          ></el-tab-pane>
        </el-tabs>
      </div>
      <div class="nrx-box">
        <div class="nr-x">
          <p class="nr-x1">新增用户数</p>
          <p class="nr-x2">
            <img src="../../assets/imgs/uasntp/1.png" alt="" /><samp
              style="color: #527498"
              >{{userCount.Count}}</samp
            >
          </p>
        </div>
        <div class="nr-x">
          <p class="nr-x1">在线用户数</p>
          <p class="nr-x2">
            <img src="../../assets/imgs/uasntp/2.png" alt="" /><samp
              style="color: #8565ff"
              >{{userCount.Count}}</samp
            >
          </p>
        </div>
        <div class="nr-x">
          <p class="nr-x1">{{tabName || ''}}总收入</p>
          <p class="nr-x2">
            <img src="../../assets/imgs/uasntp/3.png" alt="" /><samp
              style="color: #ff7e00"
              >¥ {{timeOrderInfo.income || 0}}</samp
            >
          </p>
        </div>
        <div class="nr-x">
          <p class="nr-x1">{{tabName || ''}}总支出</p>
          <p class="nr-x2">
            <img src="../../assets/imgs/uasntp/4.png" alt="" /><samp
              style="color: #3d72ff"
              >¥ {{timeOrderInfo.expend || 0}}</samp
            >
          </p>
        </div>
        <div class="nr-x">
          <p class="nr-x1">账户余额</p>
          <p class="nr-x2">
            <img src="../../assets/imgs/uasntp/5.png" alt="" /><samp
              style="color: #ff566a"
              >¥ ---</samp
            >
          </p>
        </div>
      </div>
    </div>

    <section class="nr-section">
      <div class="nr-section-s nr-section-z">
        <div class="nr-section-left mad-wrap wrap-box " style="margin-right:10px;flex:7;">
          <p>订单金额</p>
          <ul class="left-ul">
            <li>
              <p style="color: #808080; font-size: 16px">课程</p>
              <p style="color: #333333; font-size: 22px">¥ {{timeOrderInfo.study_card || 0}}</p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">权益点充值</p>
              <p style="color: #333333; font-size: 22px">¥ {{timeOrderInfo.point || 0}}</p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">消费券</p>
              <p style="color: #333333; font-size: 22px">¥ {{timeOrderInfo.coupon || 0}}</p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">会员卡</p>
              <p style="color: #333333; font-size: 22px">¥ {{timeOrderInfo.member || 0}}</p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">退款</p>
              <p style="color: #333333; font-size: 22px">¥ {{timeOrderInfo.transfer || 0}}</p>
            </li>
          </ul>
        </div>
    
        <div class="nr-left-z1 mad-wrap" style="flex:3;">
          <p>会员卡</p>
          <ul class="nr-left-ul">
            <li>
              <img src="../../assets/imgs/uasntp/1z.png" alt="" />
              <div class="nr-left-p">
                <samp class="nr-left-samp">金额</samp>
                <p class="nr-left-samp1">¥ {{timeOrderInfo.member || 0}}</p>
              </div>
            </li>
            <li>
              <img src="../../assets/imgs/uasntp/2z.png" alt="" />
              <div class="nr-left-p">
                <samp class="nr-left-samp">售出数量</samp>
                <p class="nr-left-samp1">{{timeOrderInfo.member_num || 0}}</p>
              </div>
            </li>
          </ul>
        </div>

        
      </div>

      <div class="nr-section-s nr-section-z">
       <div class="nr-section-left mad-wrap wrap-box" style="width: 760px">
          <p>消费券</p>
          <ul class="left-ul">
            <li>
              <p style="color: #808080; font-size: 16px">消费券</p>
              <p style="color: #333333; font-size: 22px">¥ {{timeOrderInfo.coupon || 0}}</p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">售出数量</p>
              <p style="color: #333333; font-size: 22px"> {{timeOrderInfo.coupon_num || 0}}</p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">佣金</p>
              <p style="color: #333333; font-size: 22px">¥ 0</p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">使用数量</p>
              <p style="color: #333333; font-size: 22px">0</p>
            </li>
          </ul>
        </div>

        <div class="nr-z-z nr-section-s mad-wrap" style="flex:1;">
          <div class="nr-section-left">
            <p>课程</p>
            <ul class="left-ul">
              <li>
                <p style="color: #808080; font-size: 16px">预约上课</p>
                <p style="color: #333333; font-size: 22px">0</p>
              </li>
              <li>
                <p style="color: #808080; font-size: 16px">已核销</p>
                <p style="color: #333333; font-size: 22px">0</p>
              </li>
              <li>
                <p style="color: #808080; font-size: 16px">结算金额</p>
                <p style="color: #333333; font-size: 22px">{{timeOrderInfo.settlement || 0}}</p>
              </li>
            </ul>
          </div>
        </div>

   
      </div>

      <div class="nr-section-x nr-section-s">
        <div class="nr-section-x1 nr-section-left mad-wrap wrap-box" style="flex:2;">
          <p>教练</p>
          <ul class="left-ul">
            <li>
              <p style="color: #808080; font-size: 16px">新增人数</p>
              <p style="color: #333333; font-size: 22px"> 0</p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">申请人数</p>
              <p style="color: #333333; font-size: 22px"> 0</p>
            </li>
          </ul>
        </div>
        <div class="nr-section-x2 nr-section-left mad-wrap wrap-box" style="flex:5;">
          <p>团长</p>
          <ul class="left-ul">
            <li class="left-li">
              <p style="color: #808080; font-size: 16px">申请人数</p>
              <p style="color: #333333; font-size: 22px"> 0</p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">新增团长</p>
              <p style="color: #333333; font-size: 22px"> 0</p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">拉新</p>
              <p style="color: #333333; font-size: 22px">0</p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">消费券</p>
              <p style="color: #333333; font-size: 22px"> 0</p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">会员卡</p>
              <p style="color: #333333; font-size: 22px"> 0</p>
            </li>
          </ul>
        </div>
      </div>

      <div class="nr-section-s nr-section-z">
        <div class="nr-right-z nr-left-z1 mad-wrap wrap-box" style="flex:3;">
          <p>机构</p>
          <ul class="nr-left-ul">
            <li>
              <img src="../../assets/imgs/uasntp/3z.png" alt="" />
              <div class="nr-left-p">
                <samp class="nr-left-samp">新增机构</samp>
                <p class="nr-left-samp1"> 0</p>
              </div>
            </li>
            <li>
              <img src="../../assets/imgs/uasntp/4z.png" alt="" />
              <div class="nr-left-p">
                <samp class="nr-left-samp">新增教练</samp>
                <p class="nr-left-samp1"> 0</p>
              </div>
            </li>
            <li>
              <img src="../../assets/imgs/uasntp/5z.png" alt="" />
              <div class="nr-left-p">
                <samp class="nr-left-samp">新增课程</samp>
                <p class="nr-left-samp1"> 0</p>
              </div>
            </li>
          </ul>
        </div>

        <div class="nr-section-x1 nr-section-left mad-wrap wrap-box" style="flex:5;">
          <p>小店</p>
          <ul class="left-ul">
            <li>
              <p style="color: #808080; font-size: 16px">新增入驻</p>
              <p style="color: #333333; font-size: 22px"> 0</p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">商户券核销</p>
              <p style="color: #333333; font-size: 22px">
                {{timeOrderInfo.coupon_settle_num || 0}}<span> ｜ </span>¥{{timeOrderInfo.coupon_settle || 0}}
              </p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">拉新</p>
              <p style="color: #333333; font-size: 22px"> 0</p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">消费券</p>
              <p style="color: #333333; font-size: 22px"> 0</p>
            </li>
            <li>
              <p style="color: #808080; font-size: 16px">会员卡</p>
              <p style="color: #333333; font-size: 22px"> 0</p>
            </li>
          </ul>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
  data() {
    return {
      activeName: 0,
      tabslist: ["今日", "本周", "本月"],
      tabName:'今日',
      pickerOptions: {
          shortcuts: [{
            text: '最近7天',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
      date: "",
      start_time:new Date().Format('yyyy-MM-dd') + " 00:00:00",
      timeOrderInfo:{},
      IncomeTotal:{
        study_card:0,
        point:0,
        member:0,
        coupon:0
      },
      IncomeTotalPrice:0,
      ExpendTotal:{
        refund:0,
        coupon:0,
        study_card:0,
      },
      ExpendTotalPrice:0,
      todayTotal:{
        income:0,
        expend:0
      },
      memberInfo:{},
      userCount:{},
      merCount:0,
      totalInfo:{}
    };
  },
  created() {
    this.assets_total();
    this.user_count();
    // this.mechanism_count()
    // this.mer_count()
    this.total_count()
    // this.order_total();
    // this.coup_count()
    // this.course_count()
  },

  methods: {
    // 时间选择
    changeTime(){
      this.activeName= "4"
      this.user_count();
      this.assets_total()
      this.tabName = ""
    },
    // tab切换
    tabChange(val){
      this.date = []
      this.tabName = val.label
      switch (val.label) {
        case "今日":
          this.start_time=new Date().Format('yyyy-MM-dd') + " 00:00:00"
          break;
          case "本周":
          this.start_time= this.getNowWeek()
          break;
          case "本月":
          this.start_time= this.getCurrentMonthFirst()
          break;
        default:
          break;
      }
    this.user_count();
    this.assets_total()
    },
    //本月
     getCurrentMonthFirst(){
        var date=new Date();
       return new Date(date.setDate(1)).Format("yyyy-MM-dd")+' 00:00:00';
    },
      // 获取本周
      getNowWeek(s) {
      let curDay = s ? new Date(s).getDay() : new Date().getDay();
      let start = s ? new Date(s).getTime() : new Date().getTime();
      let week = [];
      for (let i = 1-curDay; i < 8-curDay; i++) {
        let time = new Date(start + i * 1000 * 24 * 60 * 60);
        let data = {
          week: time.getDay(),
          day: time.getDate(),
          time: time.format("yyyy-MM-dd"),
        };
        week[data.week] = data;
      }
      return week[1].time + ' 00:00:00';
    },
  //注册用户统计
  total_count(){
    console.log('all');
      let url = "user/rechargerecord/queryTotalAll";
      let params={start_time:this.start_time}
      this.$axios({
        url,
        params,
        method:'get'
      }).then((res) => {
          this.totalInfo = res.data.data
        });
    },
    mechanism_count(){
      let url = "user/mastermechanism/masterMechanismCount";
      this.$axios
        .get(url)
        .then((res) => {
          // this.userCount = res.data.data
        });
    },
        //注册用户统计
    mer_count(){
      let url = "user/user/queryUserRegisterCountByType";
      
      let params={is_teach_paypal:true}

      this.$axios
        .get(url,{params})
        .then((res) => {
          this.merCount = res.data.data['商户']
        });
    },
    study_count(){
      let url = "user/userStudyCard/queryStudyEachTimeCount";
      this.$axios
        .get(url,{params:{type:'mechanism_offline'}})
        .then((res) => {
        });
    },
    coup_count(){
      let url = "user/couponList/queryCoupListCount";
      this.$axios
        .get(url,{params:{type:"lucky_charm_key"}})
        .then((res) => {
        });
    },
    course_count(){
      let url = "user/masterAppointment/queryCourseCount";
      this.$axios
        .get(url,{params:{start_time:"2022-01-01 00:00:00",type:'mechanism_offline'}})
        .then((res) => {

        });
    },
    //订单统计
    order_total() {
      let url = "user/rechargerecord/queryTotalAll";
      this.$axios
        .get(url, {
          params: {
            // start_time: this.start_time,
              start_time: '2022-10-01 00:00:00',
          },
        })
        .then((res) => {});
    },
    //注册用户统计
    user_count(){
      let url = "user/user/queryUserRegisterCountByType";
      
      let params={is_teach_paypal:true}
      if(this.start_time){
        params.start_time=this.start_time
      }
      if(this.date.length>0){
        params.start_time=this.date[0]+ " 00:00:00"
      }
      this.$axios
        .get(url,{params})
        .then((res) => {
          this.userCount = res.data.data
          if(this.activeName == 0){
            this.userCount.Count =  this.userCount.dayCount
          }else{
            this.userCount.Count = 0
            this.userCount.teach_paypal_ios && (this.userCount.Count += Number( this.userCount.teach_paypal_ios ))
            this.userCount.teach_paypal_mechanism_master && (this.userCount.Count += Number( this.userCount.teach_paypal_mechanism_master ))
            this.userCount.wx && (this.userCount.Count +=  Number( this.userCount.wx ))
            this.userCount.sport && (this.userCount.Count +=  Number( this.userCount.sport ))
          }
        });
    },
    //总资产统计
    assets_total() {
      let url = "user/rechargerecord/totalAssetsCount";
      let params = {
        statistics_time:  new Date().Format('yyyy-MM-dd hh:mm:ss'),
        start_time : this.start_time,
        end_time : new Date().Format('yyyy-MM-dd') + " 24:00:00" 
      }
      if(this.date.length>0){
        params.start_time = this.date[0]+ " 00:00:00"
        params.end_time = this.date[1]+ " 24:00:00"
      }
      this.$axios
        .get(url, { params: params })
        .then((res) => {
          let result = res.data.data
          let resTotal= result.statisticsTotal
          this.IncomeTotalPrice = 0
          this.ExpendTotalPrice = 0 
          resTotal.forEach(item=>{
            switch (item.rcharge_type) {
               //收入
              case "study_card":
                this.IncomeTotal.study_card = item.total_Acount
                this.IncomeTotalPrice += item.total_Acount
                break;
              case "point":
                this.IncomeTotal.point =item.total_Acount
                this.IncomeTotalPrice += item.total_Acount
                break;
              case "member":
                this.memberInfo = item
                this.IncomeTotal.member =item.total_Acount
                this.IncomeTotalPrice += item.total_Acount
                break;
              case "coupon":
                this.IncomeTotal.coupon =item.total_Acount
                this.IncomeTotalPrice += item.total_Acount
                break;
                 //支出
              case "结算":
                this.ExpendTotal.study_card = item.total_Acount
                this.ExpendTotalPrice += item.total_Acount
                break;
              case "商户券核销":
                this.ExpendTotal.coupon = item.total_Acount
                this.ExpendTotalPrice += item.total_Acount
                break;
              case "transfer":
                this.ExpendTotal.transfer = item.total_Acount
                this.ExpendTotalPrice += item.total_Acount
                break;   
              default:
                break;
            }
          })
          this.IncomeTotalPrice = this.IncomeTotalPrice.toFixed(2)
          this.ExpendTotalPrice =  this.ExpendTotalPrice.toFixed(2)
          //时间段数据
          let todatInfo = result.statisticsToday
          this.timeOrderInfo.income = 0
          this.timeOrderInfo.expend = 0
          todatInfo.forEach(item=>{
            switch (item.rcharge_type) {
               //收入
              case "study_card":
                this.timeOrderInfo.income += item.total_Acount
                this.timeOrderInfo.study_card = item.total_Acount
                break;
              case "point":
                this.timeOrderInfo.income += item.total_Acount
                this.timeOrderInfo.point = item.total_Acount
                break;
              case "member":
                this.timeOrderInfo.income += item.total_Acount
                this.timeOrderInfo.member = item.total_Acount
                this.timeOrderInfo.member_num = item.total_num
                break;
              case "coupon":
                this.timeOrderInfo.income += item.total_Acount
                this.timeOrderInfo.coupon = item.total_Acount
                this.timeOrderInfo.coupon_num = item.total_num
                break;
                 //支出
              case "结算":
                this.timeOrderInfo.expend += item.total_Acount
                this.timeOrderInfo.settlement = item.total_Acount
                break;
              case "商户券核销":
                this.timeOrderInfo.expend += item.total_Acount
                this.timeOrderInfo.coupon_settle = item.total_Acount
                this.timeOrderInfo.coupon_settle_num = item.total_num
                break;
              case "transfer":
                this.timeOrderInfo.expend += item.total_Acount
                this.timeOrderInfo.transfer = item.total_Acount
                break;   
              default:
                break;
            }
          })
          this.timeOrderInfo.income = this.timeOrderInfo.income.toFixed(2)
          this.timeOrderInfo.expend = this.timeOrderInfo.expend.toFixed(2)
        });
    },
  },
};
</script>
<style lang="less">
.container1 {
  background-color: #eaeaea;
  height: 130%;
  .mad-wrap {
    @media screen and(max-width: 1280px) {
      width: 100% !important;
    }
  }
  .top-box {
    background-color: #eaeaea;
    margin-right: 10px;
    justify-content: space-between;
    display: flex;
    width: 100%;
    @media screen and(max-width: 1280px) {
      flex-wrap: wrap;
    }
    .top1 {
      background-color: #ffffff;
      padding: 40px 30px 0 12px;
      font-size: 18px;
      min-height: 230px;
      width: 291px;
      border-left: 8px solid #ff7e00;
      @media screen and(max-width: 1280px) {
         flex:1;
    }
    }
    .top-p {
      margin-top: 20px;
      margin-left: 30px;
      display: flex;
      align-items: center;
      font-size: 26px;
      color: #434343;
    }

    .top-ul1 {
      padding-left: 10px;
      font-size: 14px;
      margin-left: 30px;
      @media screen and(max-width: 1280px) {
        margin-left: 0;
    }

      > li {
        display: flex;
        align-items: center;
        color: #b6bfce;
        margin-top: 11px;
        justify-content: space-between;

        > p {
          flex: 1;
        }
      }

      .span1 {
        display: inline-block;
        background-color: #ff7e00;
        width: 8px;
        height: 8px;
        margin-right: 10px;
      }

      .span2 {
        flex: 1;
        text-align: end;
      }
    }

    .top-right {
      font-size: 22px;
      background: linear-gradient(135deg, #fefeff 0%, #cfe7fe 100%);
      width: 886px;
      display: flex;
      flex-wrap: wrap;
      padding-top: 30px;
      padding-bottom: 30px;
      .box-nr {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 25%;
        height: 50%;
        @media screen and(max-width: 1280px) {
          width: 50%;
          height: 100px;
        }
        .imgbox {
          width: 50px;
          height: 50px;
          background-color: #3d72ff;
          margin-right: 10px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          .imgc {
            width: 35px;
            height: 35px;
          }
        }

        .top-li {
          width: 80px;
          font-size: 13px;
          margin-bottom: 5px;
        }
      }
    }
  }

  .nr-z {
    margin-top: 10px;
    background-color: #ffffff;
    padding-top: 40px;
    @media screen and(max-width: 1280px) {
      min-height: 400px;
    }
    .nr-s {
      padding-right: 20px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-bottom: 40px;
      @media screen and(max-width: 768px) {
      flex-direction: column;
    }
    }

    .nrx-box {
      display: flex;
      padding-right: 20px;
      padding-bottom: 33px;
      @media screen and(max-width: 1280px) {
        flex-wrap: wrap;
      }
      .nr-x {
        padding-top: 30px;
        padding-left: 20px;
        flex: 1;
        margin-left: 20px;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.07);
        min-width: 200px;
        @media screen and(max-width: 1280px) {
            flex:none;
            width: 100%;
            margin-bottom: 10px;
          }
        .nr-x1 {
          font-size: 16px;
          margin-bottom: 21px;
        }

        .nr-x2 {
          display: flex;
          align-items: center;
          font-size: 24px;
          margin-bottom: 5px;

          img {
            width: 26px;
            height: 28px;
            margin-right: 10px;
          }
        }
      }
    }
  }

  .nr-section {
    margin-top: 10px;


    .wrap-box{
      @media screen and(max-width: 1280px) {
          width: 100%;
          flex: none !important;
          margin-bottom: 10px;

      }
    }

    .nr-section-s {
      display: flex;
      @media screen and(max-width: 1280px) {
        flex-wrap: wrap;
      }
      .nr-section-left {
        width: 910px;
        // flex: 1;
        min-height: 170px;
        background-color: #ffffff;
        padding-top: 36px;
        @media screen and(max-width: 1280px) {
          width: 100%;
      }

        > P {
          margin-left: 20px;
          font-size: 18px;
          margin-bottom: 36px;
        }

        .left-ul {
          display: flex;
          flex-wrap: wrap;

          > li {
            flex: 1;
            @media screen and(max-width: 768px) {
              flex: none;
              width: 49%;
             }

            &:not(:last-child) {
              height: 60px;
              border-right: 1px solid #e9e9e9;
            }

            > p {
              margin-bottom: 10px;
              display: flex;
              justify-content: center;
            }
          }
        }
      }
    }

    .nr-section-z {
      margin-top: 10px;
      display: flex;
      @media screen and(max-width: 1280px) {
        flex-wrap: wrap;
      }
      .nr-left-z1 {
        width: 28%;
        flex: 1;
        background-color: #ffffff;
        padding-top: 36px;
        min-height: 144px;
        > p {
          font-size: 18px;
          color: #434343;
          margin-left: 20px;
          margin-bottom: 6px;
        }
        .nr-left-ul {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          > li {
            display: flex;
            // width: 200px;
            @media screen and(max-width: 768px) {
              width: 100%;
            }
            padding: 0 20px;
            height: 80px;
            justify-content: center;
            align-items: center;

            > img {
              width: 36px;
              height: 36px;
              margin-right: 20px;
            }

            .nr-left-samp {
              display: block;
              margin-bottom: 10px;
              font-size: 16px;
              color: #808080;
            }

            .nr-left-samp1 {
              color: #333333;
              font-size: 22px;
            }
          }
        }
      }

      .nr-z-z {
      //  flex:1;
        width:40% ;
        margin: 0 10px;
        @media screen and(max-width: 1280px) {
          margin: 0;
        }
        .nr-section-left {
          min-height: 144px;
          padding-top: 36px;

          > p {
            margin-bottom: 36px;
          }
        }
      }

      .nr-right-z {
        width: 38%;
        margin-right: 10px;
      }
    }

    .nr-section-x {
      margin-top: 10px;

      .nr-section-x1 {
        width: 49%;
        margin-right: 10px;
      }

      .nr-section-x2 {
        width: 51%;
      }
    }
  }
}
</style>
